import { Link } from "gatsby"
import React from "react"
import { rhythm } from "../utils/typography"

const Layout = ({ title, children }) => {
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header>
        <h2 style={{ marginTop: 0 }}>
          <Link
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              boxShadow: "none",
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h2>
      </header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer>
    </div>
  )
}

export default Layout
